import './styles/shapyaFonts.css';
import './styles/shapyaColors.css';
import './styles/ShapyerFormBody.css';

import { Box, Grid, Hidden } from '@material-ui/core';

import ImgFormsFluid from './imgComponents/imgFormsFluid';
import ImgLogoShapyaWhiteFluidH from './imgComponents/imgLogoShapyaWhiteFluidH';
import React from 'react';
import { useTranslation } from 'react-i18next';

const ShapyaFormBodyLeftSide = () => {
  const { t } = useTranslation();

  return (
    <div>
      <h1>{t('form.leftside.title')}</h1>
      <Hidden mdDown>
        <h3>
          {t('form.leftside.textl1')}
          <br />
          {t('form.leftside.textl2')}
          <br />
          {t('form.leftside.textl3')}
        </h3>
      </Hidden>
      <Hidden mdUp>
        <h3>
          {t('form.leftside.textl1')}
          <br />
          {t('form.leftside.textl2')}
          <br />
          {t('form.leftside.textl3')}
        </h3>
      </Hidden>
      <Hidden lgUp smDown>
        <h3>
          {t('form.leftside.textl1')} {t('form.leftside.textl2')}{' '}
          {t('form.leftside.textl3')}
        </h3>
      </Hidden>
      <br />
      <h2>{t('form.leftside.bottomtext')}</h2>
      <Hidden lgUp>
        <br />
      </Hidden>
      <Box className="formImg">
        <ImgFormsFluid />
      </Box>
      <Box className="logoImg" m={1}>
        <ImgLogoShapyaWhiteFluidH />
      </Box>
    </div>
  );
};

export default ShapyaFormBodyLeftSide;

import '../i18n';

import Footer from '../components/Footer';
import { Helmet } from 'react-helmet';
import NavbarWhite from '../components/navbar/NavbarWhite';
import React from 'react';
import ThanksMessageBody from '../components/ThanksMessageBody';

const ThanksMessage = () => (
  <React.Fragment>
    <Helmet>
      <title>Thanks Message</title>
    </Helmet>
    <NavbarWhite />
    <ThanksMessageBody />
    <Footer />
  </React.Fragment>
);

export default ThanksMessage;

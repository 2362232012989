import './styles/shapyaFonts.css';
import './styles/shapyaColors.css';
import './styles/ShapyerFormBody.css';
import './styles/shapyaMaxWidth.css';

import { Grid } from '@material-ui/core';
import { Link } from 'gatsby';
import React from 'react';
import ShapyaFormBodyLeftSide from './ShapyaFormBodyLeftSide';
import logoShapyaRed from '../images/Logo_ShapYa_Red.png';
import { useTranslation } from 'react-i18next';

const ThanksMessageBody = () => {
  const { t } = useTranslation();

  return (
    <div className="shapyaMaxWidth">
      <Grid
        container
        direction="row"
        justify="center"
        xs={12}
        className="shapyerFormMainGrid"
      >
        <Grid
          item
          xs={12}
          sm={10}
          md={4}
          className="shapyaBgMediumRed shapyaWhite shapyerFormLeftGrid"
        >
          <ShapyaFormBodyLeftSide />
        </Grid>
        <Grid
          container
          item
          direction="row"
          justify="center"
          alignItems="center"
          xs={12}
          sm={10}
          md={6}
          className="shapyerFormRightGrid"
        >
          <div>
            <h1
              className="shapyaMediumRed"
              style={{ fontSize: 'calc(28px + 6 * ((100vw - 320px) / 680))' }}
            >
              <b>{t('form.thanksmessage.title1')}</b>
              <br />
              <b>{t('form.thanksmessage.title2')}</b>
            </h1>
            <br />
            <h2 style={{ color: '#808080' }}>{t('form.thanksmessage.text')}</h2>
            <br />
            <Link to="/">
              <img src={logoShapyaRed} className="logoImg" />
            </Link>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ThanksMessageBody;
